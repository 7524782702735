import Text from 'components/Text';
import { useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';

const HeadShopInfo = () => {
  const salon = shopSelectors.info();
  const address = useMemo(() => [salon?.address || '', salon?.city || '', salon?.state || '', salon?.zip || ''].filter(o => !!o).join(', '), [salon]);
  if (!salon) return null;

  return (
    <HeadShopInfoStyled>
      <BoxInfo>
        <Text className='name'>{salon?.companyName}</Text>
        <Text className='address'>{address}</Text>
        <Text className='phone'>Phone: {formatPhone(salon.phone)}</Text>
      </BoxInfo>
    </HeadShopInfoStyled>
  );
};

export default HeadShopInfo;

const HeadShopInfoStyled = styled.div`
  background-color: #EEE;
`;

const BoxInfo = styled.div`
  padding: 0.5rem 1rem;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .name {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .phone, .address {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
`;