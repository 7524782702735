import { Form } from 'antd';
import { AxiosResponse } from 'axios';
import { pageLoading } from 'components/PageLoading';
import { ANYONE_NAILS_ID, ANYONE_STAFF } from 'features/Booking/BookingPage/components/BookingSteps/helper';
import aptApis from 'features/Booking/services/apis';
import { IRequestBooking } from 'features/Booking/services/types/api';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { IDepositPaymentResData } from 'types/deposit';
import { IBookingAptResData } from 'types/response';
import { IServiceUI } from 'types/service';
import { BOOKING_STEP, bookingSignal } from '../BookingPage/signal';
import { successData } from '../SuccessPage/signal';
import ReviewBanner from './Banner';
import BookingDetailInfo from './BookingDetailInfo';
import BookingCustomerInfo from './CustomerInfo';
import ReviewFooter from './Footer';
import BookingNote from './Note';
import BookingPartialPayment from './PartialPayment';

const BookingPageReview = () => {
  const [formDeposit] = Form.useForm<{ amount: number }>();
  const { shop_id = '' } = useParams();
  const navigate = useNavigate();
  const depositSetting = shopSelectors.depositSetting();
  const _services = shopSelectors.allServices();
  const promotion = shopSelectors.promotion();
  const staffs = shopSelectors.staff();
  const staff = useMemo(() => staffs.find(o => o.id === bookingSignal.staffId.value) || ANYONE_STAFF, [staffs, bookingSignal.staffId.value]);

  const services = useMemo(() => {
    return bookingSignal.serviceIds.value.map(o => _services.find(s => s.id?.toString() === o.toString())).filter(o => !!o) as IServiceUI[];
  }, [_services]);

  const serviceSummary = useMemo(() => {
    let total = 0;
    let duration = 0;

    services.map(o => {
      total += o.price || 0;
      duration += o.duration || 0;
    });

    return ({ total, duration });
  }, [services]);

  const depositAmountDefault = useMemo(() => {
    if (!depositSetting) return 0;
    const depositAmount = depositSetting?.depositType === 'PERCENT' ? ((serviceSummary.total * depositSetting?.depositValue) / 100) : depositSetting?.depositValue;
    return depositAmount || 0;
  }, [serviceSummary, depositSetting]);

  useEffect(() => {
    formDeposit.setFieldValue('amount', depositAmountDefault);
  }, [depositAmountDefault]);


  const time = useMemo(() => {
    const currentDate = bookingSignal.date.value;
    const timeSlot = bookingSignal.time.value || '';
    const startTime = moment(`${currentDate?.format('MM-DD-YYYY')} ${timeSlot}`, 'MM-DD-YYYY hh:mm A');
    const endTime = startTime.clone().add(serviceSummary.duration, 'minute');

    return ({ startTime, endTime });
  }, [services, serviceSummary, bookingSignal.date.value, bookingSignal.time.value]);

  const onBack = () => {
    navigate(`/v2/${shop_id}`);
    bookingSignal.onChooseTab(BOOKING_STEP.TIME_STEP);
  };



  const mappingPayload = () => {
    const values = bookingSignal.getValues();

    const { customerInfo, notes, serviceIds, staffId, isRequestStaff } = values;

    const startTime = time.startTime.format('MM/DD/YYYY HH:mm') || '';
    const endTime = time.endTime?.format('MM/DD/YYYY HH:mm') || '';
    const totalPrice = serviceSummary.total;

    const depositAmount = depositSetting?.depositType === 'PERCENT' ? ((totalPrice * depositSetting?.depositValue) / 100) : depositSetting?.depositValue;

    const isGroup = bookingSignal.isGroup.value;
    const totalPeople = bookingSignal.groupValue.value;

    const payload: IRequestBooking = {
      customerId: customerInfo?.id ? +customerInfo?.id : null,
      customerName: customerInfo?.name || '',
      bookingType: 1,
      serviceIds: serviceIds.map(o => +o),
      staffId: +(staffId || ''),
      totalPeople: isGroup ? totalPeople : 1,
      startTime,
      endTime,
      note: notes || '',
      isAnybody: values.staffId === ANYONE_NAILS_ID ? 1 : 0,
      apiKey: shop_id,
      couponCode: customerInfo?.coupon || '',
      requestStaff: isRequestStaff,
      id: null,
      phone: customerInfo?.phone || '',
    };
    if (promotion?.id) {
      payload.couponId = promotion.id;
    }

    return ({ values, payload, startTime, totalPrice, depositAmount: depositAmount || 0 });
  };

  const handleBooking = async () => {
    const { payload } = mappingPayload();
    pageLoading.value = true;
    try {
      const apt: AxiosResponse<{ payload: IBookingAptResData }> = await aptApis.bookAppointment(payload);
      if (apt.data.payload) {
        successData.value = {
          startTime: time.startTime,
          endTime: time.endTime,
          QRCode: apt.data.payload.qrCode,
          services, staff,
          note: payload.note || '',
          coupon: apt.data.payload.coupon,
          customer: {
            id: payload.customerId || null,
            name: payload.customerName || '',
            phone: payload.phone || '',
          }
        };
        navigate(`/v2/${shop_id}/success`, { replace: true });
      }
    } catch (error) { }
    finally {
      pageLoading.value = false;
    }
  };
  const handlePayment = async () => {
    try {
      const deposit = await formDeposit.validateFields();
      const { payload } = mappingPayload();
      const depositAmount = +deposit.amount;
      pageLoading.value = true;
      try {
        const apt: AxiosResponse<{ payload: IDepositPaymentResData }> = await aptApis.depositBooking(depositAmount, payload);
        if (apt.data.payload) {
          window.location.replace(apt.data.payload.information);
        } else {
          pageLoading.value = false;
        }
      } catch (error) {
        pageLoading.value = false;
      }

    } catch (error) { }
  };

  return (
    <>
      <Container>
        <Header>
          <div className="header-box">
            <button onClick={onBack} className="btn btn-back">{backBtn}</button>
            <div className="title-page">Review Booking</div>
          </div>
          <ReviewBanner depositAmount={depositSetting ? depositAmountDefault : 0} />
        </Header>
        <Content>
          <div className="section-box">
            <BookingCustomerInfo />
          </div>
          <div className="section-box">
            <BookingDetailInfo
              staff={staff}
              startTime={time.startTime} endTime={time.endTime}
              serviceSummary={serviceSummary} services={services}
            />
          </div>
          {depositSetting &&
            <div className="section-box">
              <BookingPartialPayment
                form={formDeposit}
                maxValue={serviceSummary.total}
                minValue={depositAmountDefault}
              />
            </div>
          }
          <div className="section-box">
            <BookingNote />
          </div>
        </Content>
      </Container>
      <ReviewFooter formDeposit={formDeposit} depositSetting={depositSetting} onBooking={handleBooking} onPayment={handlePayment} />
    </>
  );
};

export default BookingPageReview;

const Container = styled.div`
  padding-bottom: 150px;
`;

const Content = styled.div`
  background-color: #F2F3F5;
  padding-top: 8px;
  .section-box {
    background: #FFF;
  }
  .section-title {
    color: #5C6D8A;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .section-box {
    background: #fff;
  }

  @media (min-width:600px)  {
    padding: 8px 0;
    .section-title {
      font-size: 18px;
    }
  }
`;

const Header = styled.div`
flex-shrink: 0;
position: sticky;
top: 0;
background: #fff;
z-index: 99;

.header-box {
  position: relative;
  border-bottom: 1px solid #E5E6EB;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0 16px;
}
.btn-back {
  height: 54px;
  width: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.title-page {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 24.75px */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
`;

const backBtn = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clipPath="url(#clip0_2025_647)">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z" fill="#1D2129" />
  </g>
  <defs>
    <clipPath id="clip0_2025_647">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>;
