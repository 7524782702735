import React, { useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import StaffReviewItem, { IUIStaffReviewItem } from './StaffReviewItem';
import { IUIMediaItem } from './MediaModaReview';
import { IGalleryFileItem } from 'types/gallery';
type Props = {
  onBookWithStaff: (staffId: string) => void;
};
function checkURL(url: string) {
  return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}
const StaffReviews = ({ onBookWithStaff }: Props) => {
  const _staffs = shopSelectors.staff();
  const gallery = shopSelectors.gallery();
  const staffs = useMemo(() => {
    return gallery.map(o => ({
      staffId: o.staffId?.toString(),
      avatar: o.avatar,
      staffName: o.staffName,
      serviceNames: '',
      media: o.details.map((s: IGalleryFileItem) => ({
        id: s.galleryId?.toString(),
        source: s.urlImage,
        type: checkURL(s.urlImage) ? 'image' : 'video',
      } as IUIMediaItem))
    } as IUIStaffReviewItem));
  }, [_staffs]);
  const handleBooking = (staffId: string) => () => onBookWithStaff(staffId);
  return (
    <Container>
      {staffs.map(o => (
        <StaffReviewItem key={o.staffId} data={o} onBook={handleBooking(o.staffId)} />
      ))}
    </Container>
  );
};

export default StaffReviews;
const Container = styled.div`
  min-height: 80vh;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: #FFF;
`;
