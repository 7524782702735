import { Checkbox, Col, Row } from 'antd';
import { bookingSignal } from 'features/Booking/BookingPage/signal';
import { memo, useCallback, useEffect, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
import { ANYONE_NAILS_ID, ANYONE_STAFF } from '../../helper';
import Staff from './Staff';
import InputSearch from './InputSearch';
import Text from 'components/Text';

const StaffFeature = () => {
  const staffs = shopSelectors.staff();
  const [loading, setLoading] = useState(true);

  const handleChooseStaff = (o: IStaffUI) => () => {
    bookingSignal.staffId.value = o.id;
    bookingSignal.next();
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10);
  }, []);

  const StaffList = useCallback(() => {
    return <>
      {staffs.map(o => (
        <StaffWrap key={o.id} span={8}>
          <Staff data={o}
            active={bookingSignal.staffId.value === o.id}
            onClick={handleChooseStaff(o)}
          />
        </StaffWrap>
      ))}
    </>;
  }, [staffs]);

  if (loading) return null;
  return (
    <StaffTabStyled>
      <InputSearch onSelect={(o) => handleChooseStaff(o)()} />
      <Text my={0.5}>
        <Checkbox
          checked={!bookingSignal.isRequestStaff.value}
          className='value-section'
          onChange={(e) => {
            bookingSignal.isRequestStaff.value = !e.target.checked;
          }}>
          Anybody
        </Checkbox>
      </Text>
      <Staffs wrap gutter={[8, 8]}>
        <StaffWrap span={8}>
          <Staff
            anyone
            key={'anyone'}
            active={bookingSignal.staffId.value === ANYONE_NAILS_ID}
            onClick={handleChooseStaff(ANYONE_STAFF)}
          />
        </StaffWrap>
        <StaffList />
      </Staffs>
    </StaffTabStyled>
  );
};

export default memo(StaffFeature);

const StaffTabStyled = styled.div`
  min-height: 70vh;
  margin-bottom: 1rem;
`;

const Staffs = styled(Row)`
display: flex;
margin-top: 12px;
`;

const StaffWrap = styled(Col)`
  width: 20%;
`;