import { Checkbox, Row } from 'antd';
import Text from 'components/Text';
import { ANYONE_NAILS_ID, ANYONE_STAFF } from 'features/Booking/BookingPage/components/BookingSteps/helper';
import React, { forwardRef, memo, useImperativeHandle, useMemo, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
import { BOOKING_STEP, bookingSignal } from '../signal';
import InputSearch from './InputSearch';
import Staff from './Staff';
import { bookingTimeSlotListRef } from '../TimeTab/TimeSlotList';
type Ref = {
  submit: () => void;
};
export const staffTabRef = React.createRef<Ref>();
const StaffTab = forwardRef<Ref>(({ }, ref) => {
  const _staffs = shopSelectors.staff();
  const [textSearch, setTextSearch] = useState('');

  const staffs = useMemo(() => {
    const _textSearch = textSearch.trim().toLocaleLowerCase();
    if (!_textSearch) return _staffs;

    return _staffs.filter(o => o.name.toLocaleLowerCase().includes(_textSearch));
  }, [_staffs, textSearch]);

  const handleChooseStaff = (o: IStaffUI) => {
    bookingSignal.staffId.value = o.id;
    bookingSignal.onChooseTab(BOOKING_STEP.TIME_STEP);
    bookingTimeSlotListRef.current?.fetchTimes(o.id, bookingSignal.date.value);
  };

  const submit = () => {
    if (!bookingSignal.staffId.value) {
      bookingSignal.staffId.value = ANYONE_NAILS_ID;
      bookingTimeSlotListRef.current?.fetchTimes(ANYONE_NAILS_ID, bookingSignal.date.value);
    }
    if (!!bookingSignal.defaultStaff.value && bookingSignal.defaultStaff.value === bookingSignal.staffId.value) {
      bookingTimeSlotListRef.current?.fetchTimes(ANYONE_NAILS_ID, bookingSignal.date.value);
    }
    bookingSignal.onChooseTab(BOOKING_STEP.TIME_STEP);
  };

  useImperativeHandle(ref, () => ({
    submit: () => submit(),
  }));

  return (
    <Container>
      <InputSearch value={textSearch} onChangeText={setTextSearch} />
      <Text my={0.5}>
        <Checkbox
          checked={!bookingSignal.isRequestStaff.value}
          className='value-section'
          onChange={(e) => {
            bookingSignal.isRequestStaff.value = !e.target.checked;
          }}>
          Anybody
        </Checkbox>
      </Text>
      <Staffs className='staff-list'>
        <StaffWrap>
          <Staff
            anyone
            key={'anyone'}
            active={bookingSignal.staffId.value === ANYONE_NAILS_ID}
            onClick={() => handleChooseStaff(ANYONE_STAFF)}
          />
        </StaffWrap>
        <StaffList data={staffs} onChooseStaff={handleChooseStaff} />
      </Staffs>
    </Container>
  );
});
type StaffListProps = {
  data: IStaffUI[];
  onChooseStaff: (data: IStaffUI) => void;
};
const StaffList = ({ data, onChooseStaff }: StaffListProps) => {
  return (
    <>
      {data.map(o => (
        <StaffWrap key={o.id}>
          <Staff data={o}
            active={bookingSignal.staffId.value === o.id}
            onClick={() => onChooseStaff(o)}
          />
        </StaffWrap>
      ))}
    </>
  );
};
StaffTab.displayName = 'StaffTab';
export default memo(StaffTab);

const Container = styled.div`
  min-height: 60vh;
  @media (min-width:600px)  {
    .staff-list {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
`;

const Staffs = styled(Row)`
  display: grid;
  margin-top: 12px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
`;

const StaffWrap = styled.div`
`;