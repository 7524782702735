import { signal } from '@preact/signals-react';
import moment, { Moment } from 'moment';

export enum BOOKING_STEP {
  INFORMATION_STEP = 'INFORMATION_STEP',
  SERVICE_STEP = 'SERVICE_STEP',
  DATE_AND_STAFF_STEP = 'DATE_AND_STAFF_STEP',
  TIME_STEP = 'TIME_STEP',
  REVIEW_STEP = 'REVIEW_STEP',
}

export type IFormCustomerInfo = {
  id?: string;
  name: string;
  phone: string;
  coupon?: string;
};

const stepActive = signal<BOOKING_STEP>(BOOKING_STEP.INFORMATION_STEP);

const customerInfo = signal<IFormCustomerInfo | null>(null);

const serviceIds = signal<string[]>([]);

const date = signal<Moment | null>(moment());

const time = signal<Moment | null>(null);

const isRequestStaff = signal<boolean>(true);

const isGroup = signal<boolean>(false);

const staffId = signal<string | null>(null);

const notes = signal<string | null>(null);

const totalDuration = signal<number>(0);

const isBackToReview = signal(false);

const back = () => {
  isBackToReview.value = false;
  switch (stepActive.value) {
    case BOOKING_STEP.SERVICE_STEP: {
      stepActive.value = BOOKING_STEP.INFORMATION_STEP;
      break;
    }
    case BOOKING_STEP.DATE_AND_STAFF_STEP: {
      stepActive.value = BOOKING_STEP.SERVICE_STEP;
      break;
    }
    case BOOKING_STEP.TIME_STEP: {
      stepActive.value = BOOKING_STEP.DATE_AND_STAFF_STEP;
      break;
    }
    case BOOKING_STEP.REVIEW_STEP: {
      stepActive.value = BOOKING_STEP.TIME_STEP;
      break;
    }
    default:
      break;
  }
};

const next = () => {
  isBackToReview.value = false;
  switch (stepActive.value) {
    case BOOKING_STEP.INFORMATION_STEP: {
      stepActive.value = BOOKING_STEP.SERVICE_STEP;
      break;
    }
    case BOOKING_STEP.SERVICE_STEP: {
      stepActive.value = BOOKING_STEP.DATE_AND_STAFF_STEP;
      break;
    }
    case BOOKING_STEP.DATE_AND_STAFF_STEP: {
      stepActive.value = BOOKING_STEP.TIME_STEP;
      break;
    }
    case BOOKING_STEP.TIME_STEP: {
      stepActive.value = BOOKING_STEP.REVIEW_STEP;
      break;
    }
    default:
      break;
  }
};

const getValues = () => {
  return ({
    time: time.value,
    staffId: staffId.value,
    isGroup: isGroup.value,
    isRequestStaff: isRequestStaff.value,
    customerInfo: customerInfo.value,
    notes: notes.value,
    serviceIds: serviceIds.value,
    totalDuration: totalDuration.value || 0,
  });
};

export const bookingSignal = {
  stepActive,
  serviceIds,
  date,
  time,
  staffId,
  back,
  next,
  getValues,
  isGroup,
  isRequestStaff,
  customerInfo,
  notes,
  totalDuration,
  isBackToReview,
};