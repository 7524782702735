import { Avatar, Carousel } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { IUIMediaItem, mediaModalReviewRef } from './MediaModaReview';
import { PlayCircleOutlined } from '@ant-design/icons';
export interface IUIStaffReviewItem {
  staffId: string;
  avatar: string;
  staffName: string;
  serviceNames: string;
  media: IUIMediaItem[];
}
type Props = {
  data: IUIStaffReviewItem;
  onBook?: () => void;
};
const StaffReviewItem = ({ data, onBook }: Props) => {
  const onClickMedia = (item: IUIMediaItem) => () => {
    mediaModalReviewRef.current?.open(data.media, item.id);
  };
  return (
    <Container>
      <TopContentContainer>
        <div className="staff-container">
          <Avatar size={42} src={data.avatar}>{data.staffName?.[0] || 'A'}</Avatar>
          <div className="staff-content-container">
            <div className="staff-name">{data.staffName}</div>
            <div className="service-names">{data.serviceNames}</div>
          </div>
        </div>
        <button className="action-btn" onClick={onBook}><span>Book Now</span></button>
      </TopContentContainer>
      <MediaContainer>
        <Carousel slidesPerRow={3} centerPadding='16px' autoplay autoplaySpeed={2000}>
          {data.media?.map(o => {
            return (
              <div key={o.id} className="media-item" onClick={onClickMedia(o)}>
                {o.type === 'video' ?
                  <video src={o.source} muted /> :
                  <img src={o.source} alt={o.source} />}
                {o.type === 'video' && <div className='video-overlay'><PlayCircleOutlined /></div>}
              </div>
            );
          })}
        </Carousel>
      </MediaContainer>
    </Container>
  );
};

export default StaffReviewItem;
const Container = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
align-self: stretch;
`;

const TopContentContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
align-self: stretch;
.staff-container {
  display: flex;
  align-items: center;
  gap: 8px;
  .staff-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    .staff-name {
      color: #232F3E;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 137.5%; /* 19.25px */
    }
    .service-names {
      color: #64748B;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 137.5%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      align-self: stretch;
      overflow: hidden;
      text-overflow: ellipsis;

    }
  }
}
.action-btn {
  display: flex;
  height: 40px;
  padding: 16px 24px;
  justify-content: center;
  padding-right: 0;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  span {
    color: #0085E7;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
  }
}
`;

const MediaContainer = styled.div`
align-items: flex-start;
gap: 4px;
align-self: stretch;
.media-item {
  height: 112px;
  flex: 1 0 0;
  position: relative;
  video, img {
    object-fit: cover;
    width: calc(100% - 8px);
    height: 100%;
  }

  .video-overlay {
    position: absolute;
    z-index: 2;
    inset: 0;
    right: 8px;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #fff;
  }

  &:last-child {
    video, img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
`;