import React from 'react';
import styled from 'styled-components';
import fb_icon from './icons/facebook.svg';
import inst_icon from './icons/instagram.svg';
import gg_icon from './icons/google.svg';
import yelp_icon from './icons/yelp.svg';
import { get } from 'lodash';
import shopSelectors from 'services/shop/selectors';

const SalonContactView = () => {
  const salonInfo = shopSelectors.info();
  const destination = encodeURIComponent([salonInfo?.address, salonInfo?.city, salonInfo?.state, salonInfo?.zip].join(' '));
  const onOpenURL = (url?: string) => () => {
    if (!url) return;
    window.open(url, '_blank');
  };
  return (
    <Container>
      <Heading>
        <div className="title-section">Contact</div>
        <SocialMedia>
          <SocialMediaItem onClick={onOpenURL(salonInfo?.facebookUrl)} type={SocialMediaItemType.FACEBOOK} />
          <SocialMediaItem onClick={onOpenURL(salonInfo?.instagramUrl)} type={SocialMediaItemType.INSTAGRAM} />
          <SocialMediaItem onClick={onOpenURL(salonInfo?.googleUrl)} type={SocialMediaItemType.GOOGLE} />
          <SocialMediaItem onClick={onOpenURL(salonInfo?.yelpUrl)} type={SocialMediaItemType.YELP} />
        </SocialMedia>
      </Heading>
      <SalonAddressContainer>
        <a href={`https://maps.google.com/?q=${destination}`}>
          <a href={`https://maps.apple.com/maps?q=${destination}`}>
            <iframe
              width="100%"
              height="182px"
              style={{ border: 0, pointerEvents: 'none' }}
              loading="lazy"
              allowFullScreen={false}
              referrerPolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50470.07612198093!2d-122.47879923505106!3d37.757692829202334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1728895753839!5m2!1sen!2s"
            />
            <div className='overlay'><span>Open Maps</span></div>
          </a>
        </a>
      </SalonAddressContainer>
    </Container>
  );
};

export default SalonContactView;
const Container = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 12px;
align-self: stretch;
background: #fff;
`;

const SalonAddressContainer = styled.div`
align-self: stretch;
width: 100%;
height: 182px;
position: relative;
border-radius: 4px;
overflow: hidden;
a {
  position: absolute;
  inset: 0;
}

.overlay {
  position: absolute;
  inset: 0;
  background: rgba(0,0,0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;

const Heading = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
align-self: stretch;
.title-section {
  color: #1D2129;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%; /* 22px */
  text-transform: uppercase;
  height: 22px;
  flex: 1 0 0;
}
`;

const SocialMedia = styled.div`
display: flex;
align-items: flex-start;
gap: 24px;
.social-media-item {
  img.social-icon {
    width: 32px;
    height: 32px;
  }
  &:active {
    background: #eee;
  }
}
`;


enum SocialMediaItemType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  GOOGLE = 'GOOGLE',
  YELP = 'YELP',
}

const icons = {
  [SocialMediaItemType.FACEBOOK]: fb_icon,
  [SocialMediaItemType.INSTAGRAM]: inst_icon,
  [SocialMediaItemType.GOOGLE]: gg_icon,
  [SocialMediaItemType.YELP]: yelp_icon,
};
const SocialMediaItem = ({ type, onClick }: { type: SocialMediaItemType, onClick: () => void; }) => {
  const icon = get(icons, type);
  return (
    <button className="social-media-item" onClick={onClick}>
      <img src={icon} alt={type} className="social-icon" />
    </button>
  );
};