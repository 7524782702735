import { createSlice } from '@reduxjs/toolkit';
import { ICategoryUI } from 'types/category';
import { IServiceUI } from 'types/service';
import { IStaffUI } from 'types/staff';
import actions from './actions';
import { IShopInfoResData } from 'types/shop';
import { ICouponResItem } from 'types/response';
import { IDepositSetting } from 'types/deposit';
import { IPromotionDetail } from 'types/promotion';
import { IGalleryItem } from 'types/gallery';


const NAME_REDUCER = 'shop';

export type IStateShop = {
  staffs: IStaffUI[],
  categories: ICategoryUI[],
  allServices: IServiceUI[],
  shopInfo: IShopInfoResData | null,
  couponData: ICouponResItem[],
  depositSetting: IDepositSetting | null,
  promotion: IPromotionDetail | null,
  gallery: IGalleryItem[],
}
const initialState: IStateShop = {
  staffs: [],
  categories: [],
  allServices: [],
  shopInfo: null,
  couponData: [],
  depositSetting: null,
  promotion: null,
  gallery: [],
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.galleries.success, (state, { payload }) => {
        state.gallery = payload;
      })
      .addCase(actions.promotion.success, (state, { payload }) => {
        state.promotion = payload;
      })
      .addCase(actions.depositSetting.success, (state, { payload }) => {
        state.depositSetting = payload;
      })
      .addCase(actions.category.success, (state, { payload }) => {
        state.categories = payload;
        const services: IServiceUI[] = [];
        state.categories.map(cate => {
          cate.services.map(s => {
            services.push({
              ...s,
              cateId: cate.id,
            });
          });
        });
        state.allServices = services;
      })
      .addCase(actions.staff.success, (state, { payload }) => {
        state.staffs = payload;
      })
      .addCase(actions.coupons.success, (state, { payload }) => {
        state.couponData = payload;
      })
      .addCase(actions.shopInfo.success, (state, { payload }) => {
        state.shopInfo = payload;
      })
      ;
  },
});

const shopReducer = Slice.reducer;
export default shopReducer;
