import { Empty, Row, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import { ANYONE_NAILS_ID } from 'features/Booking/BookingPage/components/BookingSteps/helper';
import aptApis from 'features/Booking/services/apis';
import moment, { Moment } from 'moment';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { bookingSignal } from '../signal';
type Ref = {
  fetchTimes: (staffId: string, date?: Moment | null) => void;
};
export const bookingTimeSlotListRef = React.createRef<Ref>();
const BookingTimeSlotList = forwardRef<Ref>(({ }, ref) => {
  const [loading, setLoading] = useState<boolean>(false);
  const fetchData = async (_staffId: string, _date?: Moment | null) => {
    setLoading(true);
    bookingSignal.timeSlots.value = [];
    const staffId = _staffId === ANYONE_NAILS_ID ? '' : _staffId;
    const date = _date ? _date : moment();
    try {
      const res: AxiosResponse<{ payload: { timeSlots: string[] } }> = await aptApis.getTimeSlot(date?.format('YYYY-MM-DD') || '', staffId || '');
      const timeSlots = res?.data?.payload?.timeSlots;
      if (timeSlots) {
        bookingSignal.timeSlots.value = timeSlots;
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  const handleChooseTimeSlot = (o: string) => () => {
    bookingSignal.time.value = o;
  };

  useImperativeHandle(ref, () => ({
    fetchTimes: (staffId, date) => fetchData(staffId, date),
  }));
  if (loading) return (
    <Container>
      <div className="label-section">Select time</div>
      <Row align={'middle'} justify={'center'} style={{ flex: 1 }}><Spin spinning /></Row>
    </Container>
  );

  return (
    <Container>
      <div className="label-section">Select time</div>
      {!bookingSignal.timeSlots.value.length ? <Row justify={'center'}> <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Row> : null}
      <TimeSlotsContainer className='TimeSlotsContainer'>
        {bookingSignal.timeSlots.value.map(o => (
          <button
            key={o}
            type='button'
            onClick={handleChooseTimeSlot(o)}
            className={`btn btn-time-slot ${bookingSignal.time.value === o ? 'active' : ''}`}>
            <span>{o}</span>
          </button>
        ))}
      </TimeSlotsContainer>
    </Container>
  );
});
BookingTimeSlotList.displayName = 'BookingTimeSlotList';
export default BookingTimeSlotList;

const TimeSlotsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  .btn.btn-time-slot {
    display: flex;
    padding: 12px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid #C9CDD4;
    background: #fff;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.active {
      border: 1px solid #1A7EC8;
      background: #DCF0FF;
      span {
        font-weight: 500;
      }
    }
  }
`;

const Container = styled.div`
  .label-section {
    color: #1D2129;
    text-align: left;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.54px;
    margin-bottom: 8px;
  }

  @media (min-width:600px)  {
    .label-section {
      font-size: 18px;
    }
    .TimeSlotsContainer {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 16px;
       .btn.btn-time-slot {
          span {
            font-size: 18px;
          }
       }
    }
  }
`;