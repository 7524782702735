import { Moment } from 'moment';
import { signal } from '@preact/signals-react';
import { IStaffUI } from 'types/staff';
import { IServiceUI } from 'types/service';
import { ICouponResItem } from 'types/response';

export const successData = signal<{
  QRCode: string;
  startTime: Moment | null,
  endTime: Moment | null,
  staff: IStaffUI | null,
  services: IServiceUI[],
  note: string;
  coupon: ICouponResItem | null;
  customer: {
    id: string | number | null,
    name: string,
    phone: string,
  } | null,
}>({
  QRCode: '',
  startTime: null,
  endTime: null,
  staff: null,
  services: [],
  note: '',
  coupon: null,
  customer: null,
});
