import { Form, Input, InputRef, Row, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import PhoneInput from 'components/PhoneInput';
import Text from 'components/Text';
import { debounce, first } from 'lodash';
import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import aptApis from '../../../services/apis';
import { ICustomerDetailInfo } from '../../../services/types/customer';
import { BOOKING_STEP, bookingSignal, IFormCustomerInfo } from './signal';

type IFormValues = {
  name: string;
  phone: string;
  coupon: string;
};
type Ref = {
  submit: () => void;
};
export const customerTabRef = React.createRef<Ref>();

const CustomerTab = forwardRef<Ref>(({ }, ref) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const customerInfo: IFormCustomerInfo | null = bookingSignal.customerInfo.value;
  const phoneNumberRef = useRef<InputRef>(null);
  const nameRef = useRef<InputRef>(null);

  const handleFinish = (values: IFormValues) => {
    bookingSignal.customerInfo.value = {
      id: customerInfo?.id?.toString() || '',
      name: values.name,
      phone: values.phone,
      coupon: values.coupon,
    };
    bookingSignal.onChooseTab(BOOKING_STEP.SERVICE_STEP);
  };

  useEffect(() => {
    if (bookingSignal.customerInfo.value)
      form.setFieldsValue(bookingSignal.customerInfo.value);
    setTimeout(() => phoneNumberRef.current?.focus(), 1000);
  }, [bookingSignal.customerInfo.value]);

  const InputPhoneChange = useCallback(debounce<React.ChangeEventHandler<HTMLInputElement>>(async (e) => {
    const raw = e.target.value?.replace(/[^0-9]/g, '');
    if (raw.length !== 10) return;
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: ICustomerDetailInfo[] }> = await aptApis.getCustomerDetailByPhone(raw);
      const firstData = first(res.data.payload);
      if (firstData) {
        bookingSignal.customerInfo.value = {
          id: firstData.id?.toString(),
          name: firstData.name,
          phone: firstData.phone,
        };
        form.setFieldValue('name', firstData.name);
        form.validateFields();
      } else {
        form.setFieldValue('name', '');
        nameRef.current?.focus();
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }, 500), []);

  useImperativeHandle(ref, () => ({
    submit: () => form.submit(),
  }));

  return (
    <Wrapper>
      <Form layout='vertical' form={form} onFinish={handleFinish}>
        <Spin spinning={loading}>
          <Form.Item
            label={<Row><Text className='label-form'>Phone Number <span>(*)</span> </Text></Row>}
            name={'phone'}
            rules={[{ required: true, message: 'Please enter your phone number' }]}
          >
            <PhoneInput autoFocus onChange={InputPhoneChange} />
          </Form.Item>
          <Form.Item
            label={<Row><Text className='label-form'>Your Name <span>(*)</span> </Text></Row>}
            name={'name'}
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input ref={nameRef} placeholder='Please enter...' />
          </Form.Item>
        </Spin>
      </Form>
    </Wrapper>
  );
});
CustomerTab.displayName = 'CustomerTab';
export default memo(CustomerTab);

const Wrapper = styled.div`
  .label-form {
    color: #1D2129;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      color: #F05326;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .ant-form-item-has-error {
    input.phone-input {
      border-color: #f53f3f;
      box-shadow: 0 0 0 2px rgba(255, 38, 5, 0.06);
      outline: 0;
    }
  }

   @media (min-width:600px)  {
    .label-form {
      font-size: 16px;
      span {
        font-size: 16px;
      }
    }
  }
`;