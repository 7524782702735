import { Button, Checkbox, Empty, Input, Row } from 'antd';
import { debounce, first, remove, sortBy, sum } from 'lodash';
import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { ICategoryUI } from 'types/category';
import { IServiceUI } from 'types/service';
import { formatCurrency } from 'utils/formatCurrency';
import { bookingTabBarRef } from './BookingTabBar';
import { BOOKING_STEP, bookingSignal } from './signal';
import { bookingTimeSlotListRef } from './TimeTab/TimeSlotList';
type Ref = {
  submit: () => void;
};
export const servicesTabRef = React.createRef<Ref>();
const ServicesTab = forwardRef<Ref>(({ }, ref) => {
  const divRef = useRef<HTMLDivElement>(null);
  const _categories = shopSelectors.categories();
  const widthLists = useRef<number[]>([]);

  const [textSearch, setTextSearch] = useState('');

  const [activeCategory, setActiveCategory] = useState<ICategoryUI>();

  const categories = useMemo(() => {
    if (!textSearch.trim()) return _categories;
    const keyword = textSearch.trim().toLocaleLowerCase();
    const mapping = _categories.map(o => ({
      ...o,
      services: o.services.filter(s => s.name.toLowerCase().includes(keyword)),
    })).filter(o => !!o.services.length);

    return sortBy(mapping, o => o.services.length);
  }, [_categories, textSearch]);

  const activeIndex = useMemo(() => categories.findIndex(o => o.id === activeCategory?.id) || 0, [_categories, activeCategory]);

  useEffect(() => {
    const firstElement = first(categories);
    if (!firstElement) return;
    setActiveCategory(firstElement);
  }, [categories]);


  const serviceIds = bookingSignal.serviceIds.value;

  const handleChooseService = (service: IServiceUI) => {
    const temp = [...serviceIds];
    if (temp.includes(service.id)) {
      remove(temp, (o: string) => o === service.id);
    } else {
      temp.push(service.id);
    }
    bookingSignal.serviceIds.value = temp;
  };

  const handleNext = () => {
    if (!bookingSignal.serviceIds.value?.length) return;
    if (!!bookingSignal.defaultStaff.value && bookingSignal.defaultStaff.value === bookingSignal.staffId.value) {
      bookingSignal.onChooseTab(BOOKING_STEP.TIME_STEP);
      bookingTimeSlotListRef.current?.fetchTimes(bookingSignal.defaultStaff.value, bookingSignal.date.value);
    } else {
      bookingSignal.onChooseTab(BOOKING_STEP.STAFF_STEP);
    }
  };

  const onScroll = (action: 'prev' | 'next') => () => {
    if (!divRef.current || !widthLists.current) return;
    if (action === 'next') {
      const nextIdx = activeIndex + 1;
      if (nextIdx > categories.length - 1) return;
      setActiveCategory(categories[nextIdx]);
      const left = sum(widthLists.current.slice(0, nextIdx));
      divRef.current.scrollTo({ left: left, behavior: 'smooth' });
      return;
    }

    const backIdx = activeIndex - 1;
    if (backIdx < 0) return;
    setActiveCategory(categories[backIdx]);
    const left = sum(widthLists.current.slice(0, backIdx));
    divRef.current.scrollTo({ left: left, behavior: 'smooth' });
  };

  const onChooseCategory = (o: ICategoryUI, index: number) => {
    setActiveCategory(o);
    if (!divRef.current) return;
    const left = sum(widthLists.current.slice(0, index));
    divRef.current.scrollTo({ left: left || 0, behavior: 'smooth' });
  };

  const handleSearchText = (text: string) => {
    setTextSearch(text);
  };

  useImperativeHandle(ref, () => ({
    submit: () => handleNext(),
  }));

  return (
    <Wrapper>
      <InputSearch value={textSearch} onChangeText={handleSearchText} />
      {!categories.length ? <>
        <Empty description="No Result" />
      </> : <>
        <WrapCarouselStyled className='WrapCarouselStyled'>
          <CategoriesStyled ref={divRef} className='categories-list hide-scrollbar'>
            {categories.map((o, i) => (
              <div key={o.id} ref={ref => {
                if (i === 0) {
                  widthLists.current = [];
                }
                widthLists.current.push((ref?.offsetWidth || 0));
              }}>
                <CategoryStyled
                  key={o.id}
                  active={activeCategory?.id === o.id}
                  onClick={() => onChooseCategory(o, i)}
                >
                  <p>{o.name}</p>
                </CategoryStyled>

              </div>
            ))}
            <div style={{ width: '10vw', height: '100%' }} />
          </CategoriesStyled>
          <Button className='action' onClick={onScroll('prev')}><IconArrow rotate /></Button>
          <Button className='action' onClick={onScroll('next')}><IconArrow /></Button>
        </WrapCarouselStyled>
        <div>
          <ServicesStyled className='ServicesStyled container'>
            {
              activeCategory?.services?.map(o => {
                const checked = !!serviceIds.includes(o.id);
                return (
                  <ServiceStyled className='service-item' key={o.id} checked={checked} onClick={() => handleChooseService(o)}>
                    <Row className='image' ><img src={o.image} /></Row>
                    <div className='content'>
                      <div className='service-name'>{o.name}</div>
                      <Row justify={'space-between'}>
                        <p className='service-price'>{formatCurrency(o.price)}</p>
                        {checked ? <Checkbox checked /> : <div style={{ height: '25px', width: '25px' }} />}
                      </Row>
                    </div>
                  </ServiceStyled>
                );
              })
            }
          </ServicesStyled>
        </div>
      </>}
    </Wrapper>
  );
});
ServicesTab.displayName = 'ServicesTab';
export default memo(ServicesTab);
type InputSearchProps = {
  value?: string;
  onChangeText: (val: string) => void;
};
const InputSearch = ({ value, onChangeText }: InputSearchProps) => {
  const [text, setText] = useState(value);
  const _debounce = useCallback(debounce((value: string) => onChangeText(value), 500), []);
  const handleChangeText: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.target.value);
    _debounce(e.target.value);
  };
  return (
    <Row className='container'>
      <Input
        onFocus={() => setTimeout(() => {
          bookingTabBarRef.current?.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
        }, 500)}
        allowClear value={text} onChange={handleChangeText} prefix={<IconSearch />} placeholder='Service Name' />
    </Row>
  );
};


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width:600px)  {

    .ServicesStyled {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 16px;
      padding-bottom: 16px;
    }

    .categories-list {
      margin-right: 0;
      padding-right: 20vw;
      flex: 1;
    }
    .service-item {
      .image {
        img {
          width: 80px;
          height: 80px;
        }
      }
      .service-name {
        font-size: 18px;
      }
      .service-price {
        font-size: 16px;
      }
    }

    .WrapCarouselStyled {
      .action {
        display: flex;
      }
    }
  }
`;

const WrapCarouselStyled = styled.div`
display: flex;
gap: 16px;
.action {
  display: none;
  height: auto;
  width: 45px;
  min-width: 45px;
  padding: 0;
  align-items: center;
  justify-content: center;
}
`;
const ServicesStyled = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
`;
const ServiceStyled = styled.button<{ checked?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #CECECE;
  padding: 4px 8px;
  gap: 4px;
  background: #FFF;
  .image {
    img {
      width: 48px;
      height: 48px;
      border-radius: 5px;
      background: #E2E2E3;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    flex: 1;
  }
  .service-name {
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
  }
  .service-price {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1A7EC8;
    border-color: #1A7EC8;
  }
`;

const IconSearch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11.0905 19.181C12.8369 19.181 14.4676 18.6128 15.7933 17.666L19.3062 21.1938C19.5376 21.4253 19.8427 21.541 20.1583 21.541C20.8422 21.541 21.3367 21.015 21.3367 20.3417C21.3367 20.026 21.2315 19.7315 21 19.5L17.5187 15.9932C18.5602 14.6255 19.181 12.9316 19.181 11.0905C19.181 6.64019 15.5408 3 11.0905 3C6.62967 3 3 6.64019 3 11.0905C3 15.5408 6.62967 19.181 11.0905 19.181ZM11.0905 17.4345C7.6081 17.4345 4.74645 14.5623 4.74645 11.0905C4.74645 7.61862 7.6081 4.74645 11.0905 4.74645C14.5623 4.74645 17.4345 7.61862 17.4345 11.0905C17.4345 14.5623 14.5623 17.4345 11.0905 17.4345Z" fill="#EF505F" />
  </svg>
);

const CategoriesStyled = styled.div`
overflow-x: auto;
display: flex;
justify-content: space-between;
flex-wrap: nowrap;
padding-bottom: 0;
padding-right: 16px;
margin-right: -16px;
background: #E5E6EB;

`;

const CategoryStyled = styled.button <{ active?: boolean }>`
padding: 10px 1rem;
align-items: center;
gap: 8px;
margin-right: 16px;
display: flex;
align-items: center;
justify-content: center;
  p {
  color: #1D2129;
  font-family: Poppins;
  font-size: 16px;
  min-width: 5rem;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
}

  ${({ active }) => active && `
    background: #0D446C;
    p {
      color: #FFF;
    }
  `}
`;

const IconArrow = ({ rotate }: { rotate?: boolean }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17" viewBox="0 0 24 17" fill="none" style={{ transform: rotate ? 'rotate(180deg)' : 'unset' }}>
    <path d="M14.9072 0.69381C15.1344 0.69381 15.3616 0.77751 15.5409 0.956862L22.7988 8.21469C23.1455 8.56144 23.1455 9.13537 22.7988 9.48212L15.5409 16.7399C15.1942 17.0867 14.6203 17.0867 14.2735 16.7399C13.9267 16.3932 13.9267 15.8192 14.2735 15.4725L20.8976 8.8484L14.2735 2.22429C13.9267 1.87754 13.9267 1.30361 14.2735 0.956862C14.4409 0.77751 14.6801 0.69381 14.9072 0.69381Z" fill="#1D2129" />
    <path d="M1.83798 7.95178L21.9614 7.95178C22.4516 7.95178 22.8582 8.35832 22.8582 8.84855C22.8582 9.33878 22.4516 9.74532 21.9614 9.74532L1.83798 9.74532C1.34774 9.74532 0.94121 9.33878 0.94121 8.84855C0.94121 8.35832 1.34774 7.95178 1.83798 7.95178Z" fill="#1D2129" />
  </svg>
);
