import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { ICartItem } from './types/cart';
import { remove } from 'lodash';
import { IAddressData, IApiCheckOutBody, IShoppingCustomerStored } from './types/shipping';
import storage from 'utils/sessionStorage';

const initialState: IState = {
  dataDemo: [],
  products: [],
  loading: true,
  detail: {
    loading: true,
    data: null,
  },
  cart: [],
  customerInfo: null,
  customerBookingSaved: null,
  configShipping: null,
  checkedItems: [],
  transaction: {
    data: null,
    loading: true,
  },
  address: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    saveCustomerBooking: (state, { payload }: { payload: IShoppingCustomerStored }) => {
      state.customerBookingSaved = payload;
    },
    saveCartLocal: (state, { payload }: { payload: ICartItem[] }) => {
      state.cart = payload;
    },
    checkAddressDefault: (stateStore) => {
      const addressStore = storage.address.get();
      const [address = '', city = '', state = '', zip = ''] = addressStore?.split('|') || [];
      const customerInfo = stateStore.customerInfo;
      stateStore.address = {
        customerName: customerInfo?.name || '',
        customerPhone: customerInfo?.phone || '',
        address,
        city, state, zip,
      };
    },
    tryCheckoutAgain: (state, { payload }: { payload: IApiCheckOutBody }) => {
      const customerInfo = payload.customerInfo;
      state.address = {
        address: customerInfo.address,
        city: customerInfo.city,
        customerName: customerInfo.name,
        customerPhone: customerInfo.phone,
        state: customerInfo.state,
        zip: customerInfo.zip,
      };
      state.customerInfo = {
        name: customerInfo.name,
        phone: customerInfo.phone,
        id: customerInfo.customerId.toString(),
      };

      state.cart = payload.products.map(o => ({ amount: o.quantity, productId: o.productId }));
      state.checkedItems = payload.products.map(o => o.productId);
    },
    setAddressData: (state, { payload }: { payload: IAddressData }) => {
      state.address = payload;
    },
    onRemoveProductCart: (state, { payload }: { payload: string }) => {
      remove(state.checkedItems, o => o === payload);
    },
    toggleCheckItem: (state, { payload }: { payload: string }) => {
      const idx = state.checkedItems.indexOf(payload);
      if (idx === -1) {
        state.checkedItems.push(payload);
      } else {
        remove(state.checkedItems, o => o === payload);
      }
    },
    setCustomerInfo: (state, { payload }) => {
      state.customerInfo = payload;
    },
    plusProductInCart: (state, { payload }: { payload: string }) => {
      const idx = state.cart.findIndex(o => o.productId === payload);

      if (idx !== -1) {
        state.cart[idx].amount += 1;
      } else {
        state.cart.push({ productId: payload, amount: 1 });
      }
    },
    minusProductInCart: (state, { payload }: { payload: string }) => {
      const idx = state.cart.findIndex(o => o.productId === payload);
      if (idx === -1) return;
      const nextValue = state.cart[idx].amount - 1;
      if (nextValue < 0) return;
      state.cart[idx].amount = nextValue;
    },
    addToCart: (state, { payload }: { payload: ICartItem }) => {
      const idx = state.cart.findIndex(o => o.productId === payload.productId);
      if (idx !== -1) {

        state.cart[idx].amount = payload.amount;
      } else {
        state.cart.push(payload);
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(actions.getTransactionShopping.fetch, (state) => {
        state.transaction.loading = true;
        state.transaction.data = null;
      })
      .addCase(actions.getTransactionShopping.success, (state, { payload }) => {
        state.transaction.loading = false;
        state.transaction.data = payload;
      })
      .addCase(actions.getTransactionShopping.fail, (state) => {
        state.transaction.loading = false;
        state.transaction.data = null;
      })
      .addCase(actions.getConfigShipping.fetch, (state) => {
        state.checkedItems = state.cart.map(o => o.productId);
      })
      .addCase(actions.getConfigShipping.success, (state, { payload }) => {
        state.configShipping = payload;
      })
      .addCase(actions.getProductDetail.fetch, (state, { payload }) => {
        const items = state.products.find(o => o.productId.toString() === payload.id.toString());
        if (items) {
          state.detail.data = items;
          state.detail.loading = false;
        } else {
          state.detail.data = null;
          state.detail.loading = true;
        }
      })
      .addCase(actions.getProductDetail.success, (state, { payload }) => {
        state.detail.data = payload;
        state.detail.loading = false;
      })
      .addCase(actions.getProductDetail.fail, (state) => {
        state.detail.data = null;
        state.detail.loading = false;
      })
      .addCase(actions.getDemoData.success, (state, { payload }) => {
        state.dataDemo = payload;
      })
      .addCase(actions.getAllBySalon.fetch, (state) => {
        if (state.products.length) return;
        state.loading = true;
      })
      .addCase(actions.getAllBySalon.success, (state, { payload }) => {
        state.products = payload;
        state.loading = false;
      })
      .addCase(actions.getAllBySalon.fail, (state) => {
        state.loading = false;
      })
      ;
  },
});

export const shoppingUIActions = Slice.actions;
const shoppingServiceReducer = Slice.reducer;
export default shoppingServiceReducer;
