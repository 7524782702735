import { AxiosResponse } from 'axios';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';
import { IAppointmentDetailResData } from './types/appointment';
import { ITransactionDetailResData } from './types/transaction';
import { successData } from '../v2/pages/SuccessPage/signal';
import moment from 'moment';
import { sumBy } from 'lodash';
import { IStaffUI } from 'types/staff';
import { RootState } from 'store';
import { ANYONE_STAFF } from '../BookingPage/components/BookingSteps/helper';
import { pageLoading } from 'components/PageLoading';
import { ICustomerDetailByIdResData } from './types/customer';
import { BOOKING_STEP, bookingSignal } from '../v2/pages/BookingPage/signal';
import { formatPhone } from 'utils/formatPhone';

const getAptDetailInfo: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res: AxiosResponse<{ payload: IAppointmentDetailResData }> = yield call(apis.getAppointmentDetail, payload);
    const aptDetail = res?.data?.payload;
    if (aptDetail) {
      yield put(actions.detail.success(aptDetail));
    }
  } catch (error) { }
};

const getTransactionDetail: ISagaFunc<string> = function* ({ payload }) {
  yield delay(200);
  try {
    const res: AxiosResponse<{ payload: ITransactionDetailResData }> = yield call(apis.transactionDetail, payload);
    const aptDetail = res?.data?.payload;
    if (aptDetail) {
      yield put(actions.getTransactionDetail.success(aptDetail));
      if (aptDetail.status) {
        const staffs: IStaffUI[] = yield select((root: RootState) => root.shop.staffs);
        const startTime = moment(aptDetail.appointment.strStartDate, 'MM-DD-YYYY hh:mm A');
        const duration = sumBy(aptDetail.appointment.services, o => o.duration);
        const endTime = startTime.clone().add(duration, 'minute');

        successData.value = {
          startTime, endTime,
          QRCode: aptDetail.appointment.qrCode,
          services: aptDetail.appointment.services.map(o => ({
            id: o.id.toString() || '',
            name: o.serviceName || '',
            price: o.price || 0,
            image: '',
            cateId: '',
            duration: o.duration,
          })),
          staff: staffs.find(o => o.id.toString() === aptDetail.appointment?.staffId?.toString()) || ANYONE_STAFF,
          note: aptDetail.appointment.note || '',
          coupon: aptDetail.appointment.coupon,
          customer: null,
        };
      }
    } else {
      yield put(actions.getTransactionDetail.fail({}));
    }
  } catch (error) {
    yield put(actions.getTransactionDetail.fail({}));
  }
};

const initCustomerId = function* ({ payload }: { payload: string }) {
  pageLoading.value = true;
  yield delay(200);
  try {
    const res: AxiosResponse<{ payload: ICustomerDetailByIdResData }> = yield call(apis.getCustomerDetailById, payload);
    if (res.data.payload) {
      const customerInfo = res.data.payload;
      bookingSignal.customerInfo.value = {
        id: customerInfo?.customerId?.toString() || '',
        name: customerInfo.customerName,
        phone: formatPhone(customerInfo.customerPhone),
      };
      bookingSignal.onChooseTab(BOOKING_STEP.SERVICE_STEP);
    }
  } catch (error) {
  } finally {
    pageLoading.value = false;
  }
};

export default function* aptSagas() {
  yield takeLatest(actions.detail.fetch, getAptDetailInfo);
  yield takeLatest(actions.getTransactionDetail.fetch, getTransactionDetail);
  yield takeLatest(actions.initCustomerId, initCustomerId);
}