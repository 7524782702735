import { signal } from '@preact/signals-react';
import { notification } from 'antd';
import { ANYONE_NAILS_ID } from 'features/Booking/BookingPage/components/BookingSteps/helper';
import { JSONObjectBooking } from 'features/Booking/services/types/transaction';
import { get } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { bookingTimeSlotListRef } from './TimeTab/TimeSlotList';

export enum BOOKING_STEP {
  INFORMATION_STEP = 'INFORMATION_STEP',
  SERVICE_STEP = 'SERVICE_STEP',
  STAFF_STEP = 'STAFF_STEP',
  TIME_STEP = 'TIME_STEP',
}

export const scrollOffsets = {
  INFORMATION_STEP: 0,
  SERVICE_STEP: 314,
  STAFF_STEP: 449,
  TIME_STEP: 592,
};

export type IFormCustomerInfo = {
  id?: string;
  name: string;
  phone: string;
  coupon?: string;
};

export const tabBarRef = React.createRef<HTMLDivElement>();

const stepActive = signal<BOOKING_STEP>(BOOKING_STEP.INFORMATION_STEP);

const onChooseTab = (value: BOOKING_STEP) => {
  bookingSignal.stepActive.value = value;
  const offset = get(scrollOffsets, [value]) || 0;
  setTimeout(() => {
    tabBarRef.current?.scrollTo({ left: offset, behavior: 'smooth' });
  }, 10);
};

const customerInfo = signal<IFormCustomerInfo | null>(null);

const serviceIds = signal<string[]>([]);

const date = signal<Moment | null>(moment());

const time = signal<string | null>(null);

const timeSlots = signal<string[]>([]);

const isRequestStaff = signal<boolean>(true);

const isGroup = signal<boolean>(false);

const groupValue = signal<number>(0);

const staffId = signal<string | null>(null);

const notes = signal<string | null>(null);

const totalDuration = signal<number>(0);

const isBackToReview = signal(false);

const defaultStaff = signal<string | null>(null);

const setDefaultStaff = (_staffId: string | null) => {
  defaultStaff.value = _staffId;
  staffId.value = _staffId;
  onChooseTab(BOOKING_STEP.INFORMATION_STEP);
};

const getValues = () => {
  return ({
    time: time.value,
    staffId: staffId.value,
    isGroup: isGroup.value,
    isRequestStaff: isRequestStaff.value,
    customerInfo: customerInfo.value,
    notes: notes.value,
    serviceIds: serviceIds.value,
    totalDuration: totalDuration.value || 0,
  });
};

const valid = () => {
  if (!staffId.value) {
    staffId.value = ANYONE_NAILS_ID;
  }
  if (!customerInfo.value?.phone) {
    notification.info({ message: 'Please enter your phone number' });
    onChooseTab(BOOKING_STEP.INFORMATION_STEP);
    return false;
  }
  if (!serviceIds.value?.length) {
    notification.info({ message: 'Please choose services' });
    onChooseTab(BOOKING_STEP.SERVICE_STEP);
    return false;
  }

  return true;
};

const retry = (data: JSONObjectBooking) => {
  customerInfo.value = {
    name: data.customerName,
    phone: data.phone,
  };
  serviceIds.value = data.serviceIds.map(o => o.toString());
  staffId.value = data.staffId.toString();
  groupValue.value = data.totalPeople;
  isGroup.value = data.totalPeople > 1;
  const startTime = moment(data.startTime, 'MM/DD/YYYY HH:mm');
  date.value = startTime;

  bookingTimeSlotListRef.current?.fetchTimes(staffId.value || ANYONE_NAILS_ID, date.value);
  bookingSignal.time.value = startTime.format('hh:mm A');
  notes.value = data.note || '';
  isRequestStaff.value = data.requestStaff;
  onChooseTab(BOOKING_STEP.TIME_STEP);
};

export const bookingSignal = {
  stepActive,
  serviceIds,
  defaultStaff,
  setDefaultStaff,
  date,
  time,
  timeSlots,
  groupValue,
  staffId,
  getValues,
  isGroup,
  isRequestStaff,
  customerInfo,
  notes,
  totalDuration,
  isBackToReview,
  onChooseTab,
  valid,
  retry,
};