import { createAction } from 'services/actionConfigs';
import { createAsyncAction } from 'store/actionConfigs';
import { IAptSuccessData } from './types/appointment';

const PREFIX_ACTIONS = 'appointment_feature_';

const aptActions = {
  detail: createAsyncAction<string>(PREFIX_ACTIONS + 'getAppointmentDetail'),
  setSuccessData: createAction<IAptSuccessData>(PREFIX_ACTIONS + 'setSuccessData'),
  getTransactionDetail: createAsyncAction<string>(PREFIX_ACTIONS + 'getTransactionDetail'),
  initCustomerId: createAction<string>(PREFIX_ACTIONS + 'initCustomerId'),
};

export default aptActions;

