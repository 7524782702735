import { Avatar } from 'antd';
import React, { useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import bannerJpeg from '../assets/bannerSalon.jpeg';
// const banners = [
//   { id: uuid(), src: 'https://s3-alpha-sig.figma.com/img/351e/1bd1/d9ff73fc40ef2c685da8d659b9ec9cfc?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Z-r320iQFfr6suA99EdViWOpn6UEd16BCXn0JSYmrnxbb9Li8KW-CfCw9kQapum7dIUkHUOi2EIgdQBKJeoLvweC1c4o8vB7PQn0YJcv4BbULQ9MBs1t9dU8Jl-WX6oxcTjsx1jGOir1ut7d6ic7JRETED5T4zXErboaA~AUVITySNxNjsU88GJGYgM2E0mBX78c4AvUmyI6PAXnySZj~8Pl2CdK5KjNc9iv1q80YetLJN~rrppaNARgQ~-rk~uEUksOX4ZXtzymo5RzpXW5b6j0Om1CkykJ~eruG7f2il4FpRxF~hQU-c46GeBzRsXOojg4JJ~fPRKi6cQUM2jqww__' },
//   { id: uuid(), src: 'https://s3-alpha-sig.figma.com/img/b915/8b97/ff6ec77f0709d8fea480aa2c26aef981?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gYFis2axM7ruiUV9Df5cA3JO0IqbzR~AHE3x-CK9lbaou72tySXwgD06wYtktDnHwDPWiv8Rt2WLJPiw7qfclN4NWMHc50tMqwGFcOI8Dt6XkDJQkpGqOwuWeDdLDYyB~dCOJr0fN2rB63o23ee3F3sAfsz1-AleYKA-6qOz8YBdZUwDaW5D721tj4GV6H5cj~22b4hSnSM-XD8IonPNxV5mT3nWPpj-M19tf4AeLTBiUWfXQOqelHhJjN6FzRQupq8VXRjo2Dor6IEitTBdbunzB2Bc2S0oU8Qxnbm4MbXojplmWTd5eqBTUAkj1dR2ZhBiOPcu46UxrdNd6I~5WQ__' },
// ];

const BookingSalonInfo = () => {
  const salon = shopSelectors.info();
  const address = useMemo(() => [salon?.address || '', salon?.city || '', salon?.state || '', salon?.zip || ''].filter(o => !!o).join(', '), [salon]);

  return (
    <Container className='BookingSalonInfo'>
      <BannerContainer className="banner-container">
        {/* {banners.length ? (
          <BannerCarousel>
            <Carousel autoplay speed={500} >
              {banners.map(o => (
                <div key={o.id} >
                  <img src={o.src} alt={o.id} className="banner-img" style={{ width: '100%', objectFit: 'cover', aspectRatio: '16/8', height: 'unset' }} />
                </div>
              ))}
            </Carousel>
          </BannerCarousel>
        ) : <img src={salon?.bannerUrl || bannerJpeg} alt="banner" className="banner-img" style={{ width: '100%', objectFit: 'cover' }} />} */}
        <img src={salon?.bannerUrl || bannerJpeg} alt="banner" className="banner-img" style={{ width: '100%', objectFit: 'cover' }} />
        <div className="avatar-div">
          <Avatar src={salon?.logoUrl} shape='square'>{salon?.companyName?.[0] || 'A'}</Avatar>
        </div>
      </BannerContainer>
      <DetailInfo className='detail-info-container'>
        <p className="title">{salon?.companyName || '--'}</p>
        <p className="sub-title">{address}</p>
        <p className="sub-title">{formatPhone(salon?.phone || '')}</p>
      </DetailInfo>
    </Container>
  );
};

export default BookingSalonInfo;

const DetailInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  .title {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .sub-title {
    color: #5C6D8A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }
`;

const BannerContainer = styled.div`
&.banner-container {
  padding-bottom: 24px;
  position: relative;
  .banner-img {
    width: 100%;
    height: 87px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .avatar-div {
    position: absolute;
    bottom: 0;
    padding-left: 16px;
  }
  .ant-avatar {
    display: flex;
    width: 72px;
    height: 72px;
    border-radius: 15px;
    border: 2px solid #FFF;
    background: #F05326;
    align-items: center;
    justify-content: center;
    .ant-avatar-string {
      color: #FFF;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 137.5%; /* 33px */
    }
  }
}
`;

const Container = styled.div`
  background: #E5E6EB;
  padding: 16px;
  padding-top: 24px;

  @media (min-width:600px)  {
  padding-bottom: 24px;
    .banner-container {
      padding-bottom: 0;
      .banner-img {
        height: 210px;
      }
      .avatar-div {
        transform: translateY(80px);
      }
      .ant-avatar {
        width: 130px;
        height: 130px;
        border-radius: 39.375px;
        border: 5.25px solid #FFF;
        .ant-avatar-string {
          font-size: 63px;
        }
      }
    }
    .detail-info-container {
      padding-left: 166px;
      padding-top: 0px;
      .title {
        font-size: 32px;
      }
      .sub-title {
        font-size: 16px;
      }
    }
  }
`;