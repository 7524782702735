import styled from 'styled-components';
import BookingSteps from './BookingSteps';
import BookingFooterActions from './FooterActions';
import BookingPromotion from './Promotion';
import BookingSalonInfo from './SalonInfo';
import BookingTabBar, { bookingTabBarRef } from './BookingTabBar';
import { memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import aptActions from 'features/Booking/services/actions';

const BookingPageV2 = () => {
  const dispatch = useAppDispatch();
  const [query] = useSearchParams();
  const customer_id = query.get('customer_id');
  useEffect(() => {
    if (customer_id) dispatch(aptActions.initCustomerId(customer_id));
  }, []);

  return (
    <WrapStyled>
      <div className="promotion-sticky">
        <BookingPromotion />
      </div>
      <BookingSalonInfo />
      <BookingTabBar />
      <div ref={bookingTabBarRef}></div>
      <BookingSteps />
      <BookingFooterActions />
    </WrapStyled>
  );
};

export default memo(BookingPageV2);


const WrapStyled = styled.div`
  position: relative;
`;