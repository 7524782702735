import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import couponActions from '../services/actions';
import couponSelectors from '../services/selectors';
import { LoadingWrapPage } from 'components/PageLoading';
import NotFoundPage from 'NotFound';
import CouponPageStyled, { Actions, BoxInfo, Content } from './styles';
import { QRCode, Row } from 'antd';
import { RWebShare } from 'react-web-share';
import { formatCurrency } from 'utils/formatCurrency';
import BookingSalonInfo from 'features/Booking/v2/pages/BookingPage/SalonInfo';

const CouponDetailPage = () => {
  const dispatch = useAppDispatch();
  const { coupon_id = '' } = useParams();
  const state = couponSelectors.detail.state();

  useEffect(() => {
    dispatch(couponActions.getCouponDetail.fetch(coupon_id));
  }, []);

  if (state.loading) return <LoadingWrapPage />;
  if (!state.data) return <NotFoundPage />;
  const data = state.data;

  return (
    <>
      <CouponPageStyled>
        <Content>
          <div style={{ margin: '-16px', marginBottom: 16 }}>
            <BookingSalonInfo />
          </div>
          <BoxInfo>
            <p className="qr-sub-title">
              Show this QR code to cashier
            </p>
            <div className="qr-code-wrapper">
              <QRCode size={200} bordered={false} value={String(data.couponCode)} />
              <div className="qr-code-value">
                <span>{data.couponCode}</span>
              </div>
            </div>
            <div className="coupon-discount">
              <span>{data.discountType === 0 ? `${data.discountValue}%` : formatCurrency(data.discountValue)}</span>
            </div>

            <div className="coupon-title">
              <span>{data.couponName}</span>
            </div>

            <div className="coupon-expired">
              {scheduleIcon}
              <span>Expires Until:  {data.endDate ? data.endDate : '--'}</span>
            </div>

            <div className="divider-dashed"></div>

            {!!data.description &&
              <div className="coupon-description">
                <span className="title">
                  Term of use
                </span>

                <ul>
                  {
                    data.description?.map((text, i) => (
                      <li key={i} >{text?.trim()}</li>
                    ))
                  }
                </ul>
              </div>}
          </BoxInfo>
          <BoxInfo>
            {data.urlImage && <img src={data.urlImage} style={{ width: '100%', height: 'auto', borderRadius: 4, borderTop: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '-1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }} />}
          </BoxInfo>
        </Content>
      </CouponPageStyled>
      <Actions>
        <RWebShare
          data={{
            text: 'Share coupon:',
            url: location.href,
            title: 'Coupon',
          }}
          onClick={() => undefined}
        >
          <button className='outline'>
            <Row align={'middle'} justify={'center'}>
              <IconShare />
              <p style={{ marginLeft: '0.5rem' }}>Share Coupon</p>
            </Row>
          </button>
        </RWebShare>
      </Actions>
    </>
  );
};

export default CouponDetailPage;

const IconShare = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M15.8142 3.43764C15.9479 3.12564 16.3201 2.94621 16.6492 3.0144C16.8073 3.04259 16.9444 3.13554 17.0561 3.24792C18.4785 4.66964 19.9003 6.09212 21.3227 7.51384C21.5212 7.70279 21.7429 7.91194 21.7528 8.20641V8.28718C21.7399 8.4788 21.6523 8.65937 21.514 8.79194C20.0298 10.278 18.5437 11.7626 17.0587 13.2483C16.9658 13.3417 16.8591 13.4274 16.73 13.4643C16.3924 13.574 15.9852 13.4106 15.8275 13.0906C15.7387 12.9055 15.7532 12.6937 15.7509 12.494C15.7521 11.8289 15.7517 11.1641 15.7513 10.4994C14.5124 10.5089 13.2679 10.4559 12.0359 10.611C11.4663 10.694 10.8957 10.7999 10.3562 11.006C9.61528 11.2559 8.91661 11.7089 8.48766 12.3752C8.00956 13.0986 7.83852 13.9725 7.76271 14.8228C7.69756 15.6872 7.75013 16.5565 7.85718 17.4156C7.8728 17.5695 7.90937 17.7562 7.78252 17.8754C7.63623 18.0415 7.33909 18.0434 7.20613 17.8594C6.92728 17.491 6.71775 17.0773 6.48461 16.68C6.10899 15.9923 5.76613 15.2807 5.55661 14.5238C5.37908 13.9657 5.25984 13.3851 5.25032 12.7984C5.24308 11.7219 5.35737 10.6232 5.77413 9.62165C5.89908 9.35004 6.03661 9.08337 6.20651 8.83689C6.61108 8.17708 7.20918 7.65403 7.87433 7.26851C8.83318 6.67765 9.93871 6.36565 11.0465 6.20869C12.6008 5.95117 14.1821 6.00603 15.7513 5.99879C15.7517 5.29479 15.7521 4.59078 15.7509 3.88678C15.7524 3.73593 15.7547 3.57897 15.8142 3.43764Z" fill="#D2464F" />
    <path d="M3.75929 5.05951C4.29567 4.68847 4.94824 4.50218 5.59853 4.50104C6.56387 4.49608 7.52958 4.50142 8.49492 4.49837C8.62749 4.4957 8.77644 4.50751 8.87206 4.61113C9.05378 4.77761 9.01416 5.14485 8.76235 5.22028C8.24235 5.40009 7.72616 5.60275 7.25034 5.88199C7.14254 5.94599 7.02253 5.99437 6.89568 5.99742C6.42787 6.00847 5.95891 5.98485 5.49148 6.00999C4.77301 6.03971 4.12386 6.55285 3.869 7.21495C3.74862 7.52581 3.74481 7.86371 3.74786 8.19247C3.74824 11.1506 3.74786 14.1087 3.74824 17.0668C3.75015 17.3605 3.73567 17.6557 3.7791 17.9475C3.85072 18.4051 4.1151 18.8199 4.47853 19.103C4.81072 19.3796 5.24577 19.5037 5.67358 19.5018C8.8614 19.5045 12.0492 19.5015 15.2374 19.5034C15.5437 19.5026 15.8572 19.4702 16.1384 19.3396C16.6641 19.0946 17.0827 18.6074 17.2047 18.0352C17.2721 17.6912 17.2469 17.3392 17.2515 16.9914C17.2549 16.3563 17.245 15.7209 17.2561 15.0866C17.2588 14.8778 17.4614 14.7727 17.621 14.6873C17.8344 14.5822 17.9986 14.4062 18.1921 14.2725C18.3517 14.1677 18.5704 14.2428 18.6828 14.3826C18.7647 14.4824 18.7502 14.6199 18.754 14.7411C18.7471 15.7571 18.762 16.7738 18.7464 17.7898C18.725 18.7818 18.1959 19.7182 17.4153 20.3156C16.8351 20.7776 16.0911 21.01 15.3525 21.002C12.317 21.0043 9.28197 21.0024 6.24653 21.0028C5.75777 21.0005 5.26024 21.0264 4.78253 20.9011C3.98862 20.7098 3.29986 20.1879 2.83205 19.5266C2.461 19.0058 2.27014 18.3712 2.24805 17.7346V7.75743C2.26633 7.44162 2.31014 7.12542 2.40919 6.82409C2.6389 6.10333 3.141 5.48694 3.75929 5.05951Z" fill="#D2464F" />
  </svg>
);

const scheduleIcon = <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
  <path d="M15.25 6.25C15.25 3.9 13.35 2 11 2C9.975 2 9 2.375 8.25 3.025V2.5C8.25 2.225 8.025 2 7.75 2C7.475 2 7.25 2.225 7.25 2.5V4.25C7.25 4.525 7.475 4.75 7.75 4.75H7.825H9.5C9.775 4.75 10 4.525 10 4.25C10 3.975 9.775 3.75 9.5 3.75H8.925C9.5 3.275 10.225 3 11 3C12.8 3 14.25 4.45 14.25 6.25C14.25 8.05 12.8 9.5 11 9.5C9.2 9.5 7.75 8.05 7.75 6.25C7.75 5.975 7.525 5.75 7.25 5.75H2.75V5.425C2.75 5.05 3.05 4.75 3.425 4.75H5.75C6.025 4.75 6.25 4.525 6.25 4.25C6.25 3.975 6.025 3.75 5.75 3.75H3.425C2.5 3.75 1.75 4.5 1.75 5.425V13.075C1.75 14 2.5 14.75 3.425 14.75H11.075C12 14.75 12.75 14 12.75 13.075V10.125C14.225 9.45 15.25 7.975 15.25 6.25ZM11.75 13.075C11.75 13.45 11.45 13.75 11.075 13.75H3.425C3.05 13.75 2.75 13.45 2.75 13.075V6.75H6.775C6.9 7.85 7.45 8.825 8.25 9.5H8.225C7.95 9.5 7.725 9.725 7.725 10V10.25C7.725 10.525 7.95 10.75 8.225 10.75C8.5 10.75 8.725 10.525 8.725 10.25V10C8.725 9.95 8.725 9.875 8.7 9.825C9.025 10.025 9.375 10.2 9.75 10.3C9.775 10.55 10 10.725 10.25 10.725C10.45 10.725 10.6 10.625 10.7 10.45C10.8 10.45 10.9 10.475 11 10.475C11.25 10.475 11.5 10.45 11.75 10.4V13.075Z" fill="#5A5E75" />
  <path d="M4.25 7.5C3.975 7.5 3.75 7.725 3.75 8V8.25C3.75 8.525 3.975 8.75 4.25 8.75C4.525 8.75 4.75 8.525 4.75 8.25V8C4.75 7.725 4.525 7.5 4.25 7.5ZM6.25 7.5C5.975 7.5 5.75 7.725 5.75 8V8.25C5.75 8.525 5.975 8.75 6.25 8.75C6.525 8.75 6.75 8.525 6.75 8.25V8C6.75 7.725 6.525 7.5 6.25 7.5ZM4.25 9.5C3.975 9.5 3.75 9.725 3.75 10V10.25C3.75 10.525 3.975 10.75 4.25 10.75C4.525 10.75 4.75 10.525 4.75 10.25V10C4.75 9.725 4.525 9.5 4.25 9.5ZM6.25 9.5C5.975 9.5 5.75 9.725 5.75 10V10.25C5.75 10.525 5.975 10.75 6.25 10.75C6.525 10.75 6.75 10.525 6.75 10.25V10C6.75 9.725 6.525 9.5 6.25 9.5ZM4.25 11.5C3.975 11.5 3.75 11.725 3.75 12V12.25C3.75 12.525 3.975 12.75 4.25 12.75C4.525 12.75 4.75 12.525 4.75 12.25V12C4.75 11.725 4.525 11.5 4.25 11.5ZM6.25 11.5C5.975 11.5 5.75 11.725 5.75 12V12.25C5.75 12.525 5.975 12.75 6.25 12.75C6.525 12.75 6.75 12.525 6.75 12.25V12C6.75 11.725 6.525 11.5 6.25 11.5ZM8.25 11.5C7.975 11.5 7.75 11.725 7.75 12V12.25C7.75 12.525 7.975 12.75 8.25 12.75C8.525 12.75 8.75 12.525 8.75 12.25V12C8.75 11.725 8.525 11.5 8.25 11.5ZM10.25 11.5C9.975 11.5 9.75 11.725 9.75 12V12.25C9.75 12.525 9.975 12.75 10.25 12.75C10.525 12.75 10.75 12.525 10.75 12.25V12C10.75 11.725 10.525 11.5 10.25 11.5ZM11 3.5C10.725 3.5 10.5 3.725 10.5 4V6.25C10.5 6.525 10.725 6.75 11 6.75H12.5C12.775 6.75 13 6.525 13 6.25C13 5.975 12.775 5.75 12.5 5.75H11.5V4C11.5 3.725 11.275 3.5 11 3.5Z" fill="#5A5E75" />
</svg>;
